import React from 'react'
import Bottomless from '../../../img/LogoSinfondo.png'

const ImageBottomless = () => {
  return (
    <img src={Bottomless} alt="" className='imgBottomless'/>
  )
}

export default ImageBottomless
