import React from 'react'
import Saludo from '../../../img/SaludodeManos.avif'

const ImgSaludo = () => {
  return (
    <img src={Saludo} alt="" className='imgRegister'/>
  )
}

export default ImgSaludo
