import { ENV } from "../utils"

export class User {
    baseApi = ENV.BASE_API;

    async getMe(accessToken){
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.USER_ME}`

            const params = {
                headers: {
                    Authorization: `Token ${accessToken}`
                }
            }
            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result

        } catch (error) {
            throw error
        }
    }

    async getUsers(accessToken, active = undefined) {
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.USERS}?active=${active}`
            const params = {
                headers: {
                    Authorization: `Token ${accessToken}`
                },
            }

            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result
        } catch (error) {
            throw error
        }
    }

    async getUserPath(accessToken, user) {
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.USER}/${user}`
            const params = {
                headers: {
                    Authorization: `Token ${accessToken}`
                },
            }

            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result
        } catch (error) {
            throw error
        }
    }

    async updateUser(accessToken, idUser, userData){
        try {
            const data = userData;
            if(!data.password) {
                delete data.password
            }

            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                formData.append(key, data[key])
            })

            if(data.fileAvatar) {
                formData.append("avatar", data.fileAvatar)
            }

            const url = `${this.baseApi}/${ENV.API_ROUTES.USER}/${idUser}`
            const params = {
                method: "PATCH",
                headers: {
                    Authorization: `Token ${accessToken}`
                },
                body: formData,
            }

            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result

        } catch (error) {
            throw error
        }
    }

    async deleteUser(accessToken, idUser){
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.USER}/${idUser}`
            const params = {
                method: "DELETE",
                headers: {
                    Authorization: `Token ${accessToken}`
                },
            }

            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result
            
        } catch (error) {
            throw error
        }
    }
}